import React from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Icon, Container, Grid, Button } from "semantic-ui-react";
import Logo from "assets/images/Logo.svg";
import styles from "./scss/NewFooter.module.scss";
import ContactForDemo from "./Contact/ContactForDemo";

function Footer({ t }) {
  const history = useHistory();
  const isMobile = window.innerWidth <= 768;
  const changeActiveComponent = () => {
    window.open("https://api.whatsapp.com/send?phone=9345952658");
  };

  const handleTermsAndConditions = () => {
    history.push("/termsandconditions");
  };

  const handleNavigation = (path) => {
    history.push(path);
  };

  const handleServiceNavigation = (techSuite) => {
    history.push(`/${techSuite}`);
  };

  const openMap = () => {
    window.open(
      "https://maps.app.goo.gl/tYhqBL5nDMMK19QdA",
      "_blank"
    );
  };


  const handleClick = () => {
    const email = 'abhilasha.gaonkar@hrlytics.in,kayalvizhi@hrlytics.in';
    const subject = `Demo Request`;
    const body = `
    Dear HRLytics Team,
    I am reaching out to express my interest in HRLytics.

    Please find my details below:

    
    1)Name:
    2)Contact Number:
    3)Organization:
    4)Designation:
    5)Query:

    Best regards,
    [Your Name]`;

    const queryParams = {
      su: subject,
      body: body,
    };

    const queryParamsString = Object.keys(queryParams).map(key => key + '=' + encodeURIComponent(queryParams[key])).join('&');

    const isMobileView = window.innerWidth < 480;
    let mailLink = '';

    if (isMobileView) {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
        if (confirm("Would you like to open your default mail app to send an email?")) {
          window.location.href = `mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`;
        }
      } else {
        mailLink = `mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`;
        window.open(mailLink, '_blank');
      }
    } else {
      mailLink = `https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${email}&${queryParamsString}`;

      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent(body) || ''}`;
      window.open(mailLink || mailtoLink, '_blank');
    }
  };



  return (
    <>
      <div className={styles.topfooter}>
        <Grid>
          <Grid.Row columns={isMobile ? 1 : 3}>
            <Grid.Column textAlign="center" className={styles.socialBox}>
              <p className={styles.heading} fontas="sub heading">
                Contact us for Demo
              </p>
              <ContactForDemo />
            </Grid.Column>
            <Grid.Column textAlign="center" className={styles.socialBox}>
              <p className={styles.heading} fontas="sub heading">
                Connect with Us
              </p>
              <div className={styles.flex_items}>
                <Container className={styles.container}>
                  <span className={styles.icons}>
                    <a
                      href="https://www.facebook.com/hrlytics.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon
                        name="facebook f"
                        inverted
                        className={styles.iconFb}
                        size="large"
                      />
                    </a>
                    <a
                      href="https://www.instagram.com/hrlytics.in/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon
                        name="instagram"
                        inverted
                        className={styles.iconWithoutfb}
                        size="large"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/hrlyticsin/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Icon
                        name="linkedin in"
                        inverted
                        className={styles.iconWithoutfb}
                        size="large"
                      />
                    </a>
                  </span>
                </Container>
              </div>
            </Grid.Column>
            <Grid.Column textAlign="center" className={styles.socialBox}>
              <p className={styles.heading} fontas="sub heading">
                Our Location
              </p>
              <Button
                fontas="body"
                className={styles.Connectbtn}
                onClick={openMap}
                content="View in Map"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>

      <div className={styles.footer}>
        <Grid columns={2} stackable reversed="mobile tablet">
          <Grid.Column tablet={16} width={12} largeScreen={12} widescreen={12}>
            <div className={styles.text}>
              <p fontas="sub heading">Contact Us</p>
              <div>
                <span fontas="body">E-Mail</span>
                <a fontas="body" href="mailto:abhilasha.gaonkar@hrlytics.in">
                  {t("abhilasha_mam_mail_id")}
                </a>
                <a fontas="body" href="mailto:info@hrlytics.in">
                  {t("info_mail_id")}
                </a>
              </div>
              <div>
                <span fontas="body">Contact Number</span>
                <span fontas="body">+91 93459 52658</span>
              </div>
            </div>
          </Grid.Column>

          <Grid.Column tablet={16} width={4} largeScreen={4} widescreen={4}>
            <Grid columns={2}>
              <Grid.Column className={styles.PageContainer}>
                <div fontas="sub heading">Services</div>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("reclytics")}
                >
                  Reclytics
                </p>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("skillytics")}
                >
                  Skillytics
                </p>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("speedcheck")}
                >
                  Speedcheck
                </p>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("talentics")}
                >
                  Talentics
                </p>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("speedrank")}
                >
                  SpeedRank
                </p>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("humanize")}
                >
                  Humanize
                </p>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("criya")}
                >
                  Criya
                </p>
                <p
                  fontas="body"
                  onClick={() => handleServiceNavigation("campuslytics")}
                >
                  CampusLytics
                </p>
              </Grid.Column>
              <Grid.Column className={styles.PageContainer}>
                <span fontas="sub heading" onClick={() => handleNavigation("/home")}>
                  Home
                </span>
                <span fontas="sub heading" onClick={() => handleNavigation("/thive")}>
                  T-Hive
                </span>
                <span
                 fontas="sub heading"
                  onClick={() => handleNavigation("/litCertification")}
                >
                  LIT Certification
                </span>
                <span
                 fontas="sub heading"
                  onClick={() => handleNavigation("/aboutus")}
                >
                  About Us
                </span>
                <span
                 fontas="sub heading"
                  onClick={() => handleNavigation("/thoughts")}
                >
                  Thoughts
                </span>
                <span
                 fontas="sub heading"
                  onClick={() => handleNavigation("/longitudinaldigitalprofiles")}
                >
                  LDP
                </span>
              </Grid.Column>
            </Grid>
          </Grid.Column>

          {/* <Grid.Column>
          <buttonss
            onClick={() => changeActiveComponent("ContactUs")}
            className={styles.contact_btn}
          >
            {t("contact_us")}
          </buttonss>
          <div className={styles.flex_items}>
            <Container className={styles.container}>
              <span className={styles.icons}>
                <a
                  href="https://www.facebook.com/hrlytics.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    name="facebook f"
                    inverted
                    className={styles.iconFb}
                    size="big"
                  />
                </a>
                <a
                  href="https://www.instagram.com/hrlytics.in/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    name="instagram"
                    inverted
                    className={styles.iconWithoutfb}
                    size="big"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/hrlyticsin/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon
                    name="linkedin in"
                    inverted
                    className={styles.iconWithoutfb}
                    size="big"
                  />
                </a>
              </span>
            </Container>
          </div>
        </Grid.Column> */}
          {/* <Grid.Column>
          <div className="gmap_canvas">
            <Icon size="big" name="location arrow" />
            <p>{t("hrlytics_private_ltd")}</p>
            <p>{t("third_floor")}</p>
            <p>{t("block_east")}</p>
            <p>{t("jayanagar_bengaluru")}</p>
            <p>{t("karnataka")}</p>
          </div>
          <iframe
            title="location"
            width="370"
            height="270"
            id="gmap_canvas"
            src="https://maps.google.com/maps?q=hrlytics &t=&z=12&ie=UTF8&iwloc=&output=embed"
          />
        </Grid.Column> */}
        </Grid>

        <Grid columns={2}>
          <Grid.Column textAlign="center">
            <div className={styles.button}>
              <img
                src={Logo}
                alt="logo"
                className={styles.logoFooter}
                onClickCapture={() => {
                  window.location.href = "/home";
                }}
              />
            </div>
            <div
              fontas="sub heading"
              className="termsDesign"
              onClick={handleTermsAndConditions}
            >
              Terms and Conditions
            </div>
          </Grid.Column>
        </Grid>
      </div>
    </>
  );
}

export default withTranslation("translations")(Footer);
