import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useHistory } from 'react-router-dom';
import DesktopHeader from "./DesktopHeader";
import MobileHeader from "./MobileHeader";

const Headers = (props) => {
  const currentPath = window.location.pathname.substring(1);
  const [visible, setvisible] = useState(false);
  const [activeStatus, setactiveStatus] = useState(currentPath || "");
  const history = useHistory();
  const isMobile = window.innerWidth <= 768;

  // const headerMenuItems = [
  //   { url: 'Thoughts', text: 'thoughts' },
  //   // { url: 'Platform', text: 'platform' },
  //   { url: 'Services', text: 'services' },
  //   { url: 'Thive', text: 't_hive' },
  //   { url: 'LITCertification', text: 'lit_certification' },
  //   { url: 'Careers', text: 'about_us' },
  // ];
  const headerMenuItems = [
    { url: 'thoughts', text: 'thoughts' },
    // { url: 'Platform', text: 'platform' },
    { url: 'services', text: 'services' },
    { url: 'thive', text: 't_hive' },
    { url: 'litcertification', text: 'lit_certification' },
    { url: 'aboutus', text: 'about_us' },
    { url: 'longitudinaldigitalprofiles', text: 'LDP' },
  ];

  const handleTab = (tabname) => {
    history.push(`/${tabname}`);
    setactiveStatus(tabname);
    setvisible(false);
    window.localStorage.setItem('activeTab', '');
    window.localStorage.removeItem("selectedService");
    if (tabname === 'home') window.localStorage.clear();
  };

  return (
    <>
      {!isMobile ? (
        <DesktopHeader
          activeStatus={activeStatus}
          handleTab={handleTab}
          headerMenuItems={headerMenuItems}
        />
      ) : (
        <MobileHeader
          visible={visible}
          setvisible={setvisible}
          activeStatus={activeStatus}
          handleTab={handleTab}
        />
      )}
    </>
  );
};

export default withTranslation("translations")(Headers);
