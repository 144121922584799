import React, { Suspense, useCallback, useMemo, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Switch, Route, useLocation } from "react-router-dom";
import { lazy } from "react";
import HomeScreen from "./screens/home/HomeScreen";
import NewFooter from "./globals/components/NewFooter";
import styles from "./App.module.scss";
import useConstructor from "globals/utils/useConstructor";
import { PrivateRoute, updateUserInfo } from "globals/utils/index";
// import RightSideNav from "globals/components/RightSideNav";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-phone-input-2/lib/style.css";
import DefaultLoader from "globals/components/loader/DefaultLoader";
import useSaveScrollPosition from "globals/utils/useSaveScrollPosition";
import './assets/scss/common.scss';
// import AboutUsScreen from "./screens/careers/AboutUsScreen";
const AdminPage = lazy(() => import("screens/admin/adminPage"));
const BlogPage = lazy(() => import("screens/admin/blogPage"));
const Login = lazy(() => import("screens/admin/Login"));
const JobRequirement = lazy(() => import("screens/admin/JobRequirement"));
const JobDescriptionView = lazy(() => import("screens/admin/JobDescriptionView"));
const BlogDescription = lazy(() => import("screens/thoughts/BlogDescription"));
const OurServicesShowPage = lazy(() => import("./globals/components/ourservices/OurServicesShowPage"));
const THiveScreen = lazy(() => import("screens/thive/THiveScreen"));
const LongitudinalDigitalProfiles = lazy(() => import("screens/LongitudinalDigitalProfiles/LongitudinalDigitalProfiles"))
const CampusLytics = lazy(() => import("screens/campusLytics/CampusLytics"));
const CandidatesDetails = lazy(() => import("screens/thive/CandidatesDetails"));
const TechInnovators = lazy(() => import("screens/thive/TechInnovators"));
const CourseLandingPage = lazy(() => import("./screens/services/Courses/CourseLandingPage"));
const TermsAndConditions = lazy(() => import("globals/components/TermsAndConditions"));
const ShowSingBlogDetails = lazy(() => import("screens/thoughts/ShowSingBlogDetails"));
const ViewAllBlogList = lazy(() => import("screens/thoughts/ViewAllBlogList"));
const LITCertificationLandingPage = lazy(() => import("screens/services/Courses/LiteCertification/LITCertificationLandingPage"));
const LITCertification = lazy(() => import("screens/services/Courses/LiteCertification/LITCertification"));
const JobDetailsShowPage = lazy(() => import("globals/components/ourservices/JobDetailsShowPage"));
const CourseDetailsMainComponent = lazy(() => import("globals/components/ourservices/CourseDetailsMainComponent"));
const ThoughtScreen = lazy(() => import("screens/thoughts/ThoughtScreen"));
// const PlatformScreen = lazy(() => import("screens/platform/PlatformScreen"));
const ServicesScreen = lazy(() => import("screens/services/ServicesScreen"));
const ShowCoursePaymentDetails = lazy(() => import("screens/services/Courses/SkilllyticsCourse/ShowCoursePaymentDetails"));
const LitCertificationLandingPanel = lazy(() => import('screens/litCertification/LitCertificationLandingPanel'));
const AboutUsScreen = lazy(() => import("screens/careers/AboutUsScreen"));
const TalenticsComponent = lazy(() => import("globals/components/ourservices/talentics/TalenticsComponent"));
const SpeedRankComponent = lazy(() => import("globals/components/ourservices/SpeedRankComponent"));
const SpeedCheckLandingPage = lazy(() => import("globals/components/ourservices/speedCheck/SpeedCheckLandingPage"));
const ReclyticsLandingPage = lazy(() => import("globals/components/ourservices/reclytics/ReclyticsLandingPage"));
const SkillyticsComponent = lazy(() => import("globals/components/ourservices/SkillyticsComponent"));
const CriyaComponent = lazy(() => import("globals/components/ourservices/CriyaComponent"));
const HumanizeLandingPage = lazy(() => import("screens/Humanize/HumanizeLandingPage"));




function App() {
  useConstructor(() => {
    updateUserInfo();
  });

  console.log('resolution-->', window.innerWidth, window.innerHeight);

  const location = useLocation();
  const ref = useRef();
  const excludedPaths = useMemo(() => ["/login", "/adminPage"], []);

  const checkHeaderFooterRequired = useCallback(
    (path) => {
      let mobileExcludedPaths
      if (window.innerWidth <= 560) {
        mobileExcludedPaths = [...excludedPaths, "/reclytics/jobDetails"];
        return !mobileExcludedPaths.some((excludedPath) =>
          path.startsWith(excludedPath)
        );
      }
      return !excludedPaths.some((excludedPath) =>
        path.startsWith(excludedPath)
      );
    },
    [excludedPaths]
  );

  const isHeaderFooterRequired = checkHeaderFooterRequired(location.pathname);

  useSaveScrollPosition();

  return (
    <Suspense fallback={<DefaultLoader />}>
      <div className={styles.App}>
        <div
          className={!isHeaderFooterRequired ? styles.right : styles.left}
          ref={ref}
        >
          <Switch>
            <Route
              path="/skillytics/course/:course_pack"
              component={CourseDetailsMainComponent}
            />
            <Route path="/home" component={HomeScreen} />
            <Route
              path="/thoughts/singleBlog/:blog_id"
              component={ShowSingBlogDetails}
            />
            <Route path="/thoughts/viewAllBlogs" component={ViewAllBlogList} />
            <Route path="/thoughts" component={ThoughtScreen} />
            {/* <Route path="/Platform" component={PlatformScreen} /> */}
            <Route
              path="/services/course_payment"
              component={ShowCoursePaymentDetails}
            />
            <Route
              path="/litcertificationCourses/:pack_type"
              component={LITCertification}
            />
            <Route path="/services/:pack_type" component={CourseLandingPage} />
            <Route path="/services" component={ServicesScreen} />
            <Route path="/aboutus" component={AboutUsScreen} />
            <Route path="/thive/candidateDetails" component={CandidatesDetails} />
            <Route path="/thive" component={THiveScreen} />
            <Route path="/longitudinaldigitalprofiles" component={LongitudinalDigitalProfiles} />
            <Route
              path="/techInnovators/:workflowid"
              component={TechInnovators}
            />
            <Route
              path="/campuslytics"
              component={CampusLytics}
            />
            <Route
              path="/litCertification"
              component={LitCertificationLandingPanel}
            />
            <Route
              path="/reclytics/jobDetails/:u_id"
              component={JobDetailsShowPage}
            />
            <Route
              path="/reclytics/jobDetails"
              component={JobDetailsShowPage}
            />
            <Route
              path="/Our_hr_tech_suite"
              component={OurServicesShowPage}
            />
            <Route
              path="/Talentics"
              component={TalenticsComponent}
            />
            <Route
              path="/speedrank"
              component={SpeedRankComponent}
            />
            <Route
              path="/SpeedCheck"
              component={SpeedCheckLandingPage}
            />
            <Route
              path="/reclytics"
              component={ReclyticsLandingPage}
            />
            <Route
              path="/skillytics"
              component={SkillyticsComponent}
            />
            <Route
              path="/criya"
              component={CriyaComponent}
            />
            <Route
              path="/humanize"
              component={HumanizeLandingPage}
            />
            <Route path="/blogs/:blog_id" component={BlogDescription} />
            <Route path="/login" component={Login} />
            <Route path="/termsandconditions" component={TermsAndConditions} />
            <PrivateRoute
              exact
              path="/jobRequirement"
              component={JobRequirement}
            />
            <PrivateRoute
              path="/jobRequirement/show/:id"
              component={JobDescriptionView}
            />
            <PrivateRoute exact path="/adminPage" component={AdminPage} />
            <PrivateRoute
              exact
              path="/adminPage/blog/:blog_id"
              component={BlogPage}
            />
            <Route path="/" component={HomeScreen} />
            {/* {isHeaderFooterRequired && <RightSideNav />} */}
          </Switch>
          {isHeaderFooterRequired && <NewFooter />}
        </div>
        {/* {isHeaderFooterRequired && (
          <>
            <RightSideNav />
          </>
        )} */}
      </div>
    </Suspense>
  );
}

export default withTranslation("translations")(App);
