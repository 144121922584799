import React from "react";
import { Image, Segment, Grid } from "semantic-ui-react";
import style from "./scss/aboutplatform.module.scss";
import AutomatedCandidateSourcing from "assets/images/home/automatedcandidatesourcing.svg";
import AIIntegrationWithTrackingSystem from "assets/images/home/aiintegtrackingsystem.svg"
import AutomatedInterviewProcess from "assets/images/home/automatedinterviewprocess.svg";
import AIDrivenProctoring from "assets/images/home/aidrivenproctoring.svg";
import AutoEvaluation from "assets/images/home/autoevaluation.svg";
import scientificanddatadriven from 'assets/images/home/scientificanddatadriven.svg';
import realtimecodingenvironment from 'assets/images/home/realtimecodingenvironment.svg';
import StrategicThinking from "assets/images/home/strategicthinking.svg";
import AccessAnalytics from 'assets/images/home/accessanalytics.svg';
import LifeSkillEnhancement from "assets/images/home/lifeskillenhancement.svg";
import ConsultationAndEnhancement from "assets/images/home/consultationandenhancement.svg";
import CompAndFeedbackMech from "assets/images/home/compandfeedbackmech.svg";
import CustomizedDesignofEngagement from "assets/images/home/customizeddesignofengagement.svg";
import BehaviouralAndExpressiveSkill from "assets/images/home/behaviourandexpressiveskill.svg";
import SelfAwareness from "assets/images/home/selfawareness.svg";
import ComprehensiveWellness from "assets/images/home/comprehensivewellness.svg";
import FullGroup from "assets/images/home/animation_group/FullGroup.png";
import AnimateGroup from "./AnimateGroup";

const AboutPlatform = () => {
  const isMobile = window.innerWidth <= 768;

  const keyFeatureArray = [
    { icon: AutomatedCandidateSourcing, feature: 'Automated Candidate Sourcing' },
    { icon: AIIntegrationWithTrackingSystem, feature: 'AI Integration with Tracking System' },
    { icon: AutomatedInterviewProcess, feature: 'Automated Interview Process' },
    { icon: realtimecodingenvironment, feature: 'Real-Time Coding Environment and Multimodal Assessments' },
    { icon: AIDrivenProctoring, feature: 'AI Driven Proctoring' }, 
    { icon: AutoEvaluation, feature: 'Auto Evaluation' },
    { icon: CompAndFeedbackMech, feature: 'Components and Feedback Mechannism' },
    { icon: AccessAnalytics, feature: 'Assessment Analytics and Insights' },  
    { icon: StrategicThinking, feature: 'Strategic Thinking' },
    { icon: LifeSkillEnhancement, feature: 'Life Skill Enhancement' },
    { icon: ConsultationAndEnhancement, feature: 'Consultation and Enhancement' },
    { icon: CustomizedDesignofEngagement, feature: 'Customised Design of Engagement' },
    { icon: BehaviouralAndExpressiveSkill, feature: 'Behavioural and Expressive Skill' },
    { icon: SelfAwareness, feature: 'Self Awareness' },
    { icon: ComprehensiveWellness, feature: 'Comprehensive Wellness' },
    { icon: scientificanddatadriven, feature: 'Scientific and Data Driven' },
  ];

  const getPlatFormHeaderContent = () => (
    <>
      <div className={style.platformheader} fontas="hero header">
        HRLytics - The Platform
      </div>
      <div fontas="header body" className={style.platformhead2}>
        HR Tech for Enhanced Productivity
      </div>
      <p fontas="sub heading">
        HRLytics presents an all-encompassing integrated platform
        that is indispensable for organizations seeking to leverage
        advanced HR technology to significantly boost their
        productivity and align their HR processes and activities
        with their business objectives.
      </p>
    </>
  );

  return (
    <>
      <Segment className={style.platformseg}>
        <div className={style.platformOuter}>
          <div className={style.platformcontent}>
            <Grid>
              <Grid.Row>
                <Grid.Column
                  mobile={16}
                  tablet={16}
                  computer={11}
                  largeScreen={11}
                  widescreen={11}
                >
                  <div className={style.platformleft}>
                    {isMobile ? (
                      <div className={style.mobilePlatform}>
                        <div>
                          {getPlatFormHeaderContent()}
                        </div>
                        <Image src={FullGroup} />
                      </div>
                    ) : (
                      <>
                        {getPlatFormHeaderContent()}
                      </>
                    )}
                    <p fontas="sub heading">
                      {`HRLytics combines cutting-edge sourcing and recruitment technology
                  with AI-powered interactive assessment engines for skill and talent
                  profiling, competency mapping, and skill alignment with the
                  organization's vision and business roadmap. Notably, it incorporates
                  sophisticated AI-enabled background verification (BGV) checks that
                  correlate with productivity and decision-making in recruitment.`}
                    </p>
                    <p fontas="sub heading">
                      Additionally, it offers pursuit features for optimal skill
                      and business fitment, as well as predictive features for
                      aligning human resources with their career path and
                      organizational needs. The platform operates on a data-driven
                      approach, scientifically exploring and modeling the
                      potential of human resources to drive maximum productivity.
                      Furthermore, it generates compelling reports and analytics
                      that provide personalized and in-depth insights for adaptive
                      customization of the HR ecosystem.
                    </p>
                    {/* {!isMobile && (
                      <div style={{ display: "flex", margin: "2rem 0rem" }}>
                        <Button
                          onClick={() => handleTab("Platform")}
                          className={style.knowbtn}
                        >
                          Know More
                        </Button>
                      </div>
                    )} */}
                  </div>
                </Grid.Column>
                {!isMobile && (
                  <Grid.Column
                    mobile={16}
                    tablet={16}
                    computer={5}
                    largeScreen={5}
                    widescreen={5}
                  >
                    <div className={style.platformright}>
                      <AnimateGroup />
                    </div>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </div>
        </div>
      </Segment >
      <div className={style.keyfeatures}>
        <div className={style.header} fontas="header body">Key Features</div>
        <Grid>
          <Grid.Row>
            {keyFeatureArray.map((ele, index) => (
              <Grid.Column key={`${ele.feature}-${index}`} computer={4} mobile={8} tablet={4} className={style.featurerow}>
                <div className={style.featureItem}>
                  <Image src={ele.icon} alt={ele.feature} />
                  <span>{ele.feature}</span>
                </div>
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </div>
    </>
  );
};

export default AboutPlatform;
